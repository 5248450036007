import {
  getActiveSpan,
  getDynamicSamplingContextFromSpan,
  getRootSpan,
  spanToTraceHeader,
} from '@sentry/core';
import { dynamicSamplingContextToSentryBaggageHeader } from '@sentry/utils';

export const getTraceAndBaggage = () => {
  const span = getActiveSpan();
  const rootSpan = span && getRootSpan(span);

  if (rootSpan) {
    const dynamicSamplingContext = getDynamicSamplingContextFromSpan(rootSpan);

    const sentryBaggage = dynamicSamplingContextToSentryBaggageHeader(
      dynamicSamplingContext
    );

    if (!sentryBaggage) {
      return null;
    }

    return {
      sentryBaggage,
      sentryTrace: spanToTraceHeader(span),
    };
  }

  return null;
};
