import * as Sentry from '@sentry/browser';
import { getTraceAndBaggage } from './util/sentry';
import axios from 'axios';

const FORM_SCRIPTS_URL = import.meta.env.VITE_FORM_SCRIPTS_URL;

Sentry.init({
  dsn: 'https://d8c10659c145c1d80f98d9d317b75b24@o4507777456340992.ingest.us.sentry.io/4507777565917184',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/kelleher-forms-api\.onrender\.com/,
    /^https:\/\/services\.kellehermatch\.com/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const fetchManifest = async () => {
  Sentry.addBreadcrumb({
    category: 'connection',
    message: 'Fetch started',
    level: 'info',
  });

  const traceHeaders = getTraceAndBaggage();

  try {
    const preconnectLink1 = document.createElement('link');
    preconnectLink1.rel = 'preconnect';
    preconnectLink1.href = 'https://fonts.googleapis.com';
    document.head.appendChild(preconnectLink1);

    const preconnectLink2 = document.createElement('link');
    preconnectLink2.rel = 'preconnect';
    preconnectLink2.href = 'https://fonts.gstatic.com';
    preconnectLink2.crossOrigin = 'anonymous';
    document.head.appendChild(preconnectLink2);

    const sentryMeta1 = document.createElement('meta');
    sentryMeta1.name = 'sentry-trace';
    sentryMeta1.content = traceHeaders.sentryTrace;

    const sentryMeta2 = document.createElement('meta');
    sentryMeta2.name = 'baggage';
    sentryMeta2.content = traceHeaders.sentryBaggage;
    document.head.appendChild(sentryMeta1);
    document.head.appendChild(sentryMeta2);

    Sentry.addBreadcrumb({
      category: 'connection',
      message: 'Manifest fetch',
      level: 'info',
    });

    const response = await axios.get(`${FORM_SCRIPTS_URL}/manifest.json`);
    const manifestData = response.data;

    Sentry.addBreadcrumb({
      category: 'connection',
      message: 'Manifest received',
      data: manifestData,
      level: 'info',
    });

    const formUrl = manifestData['form.js']?.file;

    if (formUrl) {
      // Create a new script element
      const script = document.createElement('script');
      // Set the src attribute to the concatenated externalUrl and formUrl
      script.src = `${FORM_SCRIPTS_URL}/${formUrl}`;
      script.type = 'module';
      // Append the script element to the head of the document
      document.head.appendChild(script);
      // Pass the trace context to the loaded script
    } else {
      console.error('form.js not found in manifest');
    }

    return manifestData;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching manifest:', error);
    throw error;
  }
};

// Fetch the manifest when the script is loaded

fetchManifest();
